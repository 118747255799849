//风险管控
import { request } from "./request"

// 添加风险
export function addRiskToProject(msg) {
    return request({
        url: "api/risk/crt/",
        method: "post",
        data: msg
    })
}

//获取风险
export function getRiskToProject(msg) {
    return request({
        url: "api/risk/get/",
        methods: "get",
        params: msg
    })
}

//修改风险
export function editRiskToProject(msg) {
    return request({
        url: "api/risk/upd/",
        method: "post",
        data: msg
    })
}

//删除风险
export function deleteRiskToProject(msg) {
    return request({
        url: "api/risk/del/",
        method: "post",
        data: msg
    })
}

//批量删除风险
export function batchDeletionRisk(msg) {
    return request({
        url: "api/risk/del/",
        method: "get",
        params: msg
    })
}


//删除风险
export function showRiskToProject(msg) {
    return request({
        url: "api/partial/show/",
        method: "get",
        params: msg
    })
}

// 分级管控
export function controlToRisk(msg) {
    return request({
        url: "api/risk/control/",
        method: "post",
        data: msg
    })
}

// 隐患排查
export function screeningToRisk(msg) {
    return request({
        url: "api/risk/hidden_risk_cat/",
        method: "post",
        data: msg
    })
}

// 获取所有项目的风险
export function getProjectRiskInfo(msg) {
    return request({
        url: "v3/014/",
        method: "post",
        data: msg
    })
}

// 获取指定项目下的风险
export function getSecondRiskInfo(msg) {
    return request({
        url: "api/ris_w/",
        method: "get",
        params: msg
    })
}

// 获取选中的风险详细信息
export function getThreeRiskInfo(msg) {
    return request({
        url: "api/ris_i/",
        method: "get",
        params: msg
    })
}

// 获取所有项目风险统计数量
export function getRiskNum(data) {
    return request({
        url: "v3/015/",
        method: "get",
        params:data
    })
}

// 获取选中的项目下 风险统计
export function getProjectRiskNum(msg) {
    return request({
        url: "api/ris_c/",
        method: "post",
        data: msg
    })
}

// 获取所有项目重大风险数据
export function getMajorProjectRisk(msg) {
    return request({
        url: "v3/016/",
        method: "get",
        params: msg
    })
}

// 获取单个项目重大风险数据
export function getMajorRisk(msg) {
    return request({
        url: "api/ris_z/",
        method: "post",
        data: msg
    })
}


// 获取与作业活动清单
export function add_zuoye(msg) {
    return request({
        url: "api/Risk_list_all/",
        method: "post",
        data: msg
    })
}


// 添加作业活动清单
export function all_zuoye(msg) {
    return request({
        url: "api/crt_Risk_list_all/",
        method: "post",
        data: msg
    })
}



// 添加设备清单
export function all_shebei(msg) {
    return request({
        url: "api/crt_equipment_list_all/",
        method: "post",
        data: msg
    })
}



// 获取风险库所有设备
export function add_Data_Risk(msg) {
    return request({
        url: "api/add_Data_Risk/",
        method: "post",
        data: msg
    })
}






// 添加
export function add_table_risk(msg) {
    return request({
        url: "api/add_table_risk/",
        method: "post",
        data: msg
    })
}




// 添加
export function get_name_risk(msg) {
    return request({
        url: "api/get_name_risk/",
        method: "post",
        data: msg
    })
}



// 添加
export function del_data_qingdan(msg) {
    return request({
        url: "api/del_data_qingdan/",
        method: "post",
        data: msg
    })
}


//风险台账自定义风险
export function TheCustom(msg){
    return request({
        url:'api/Risk_User_create/',
        method:"post",
        data:msg
    })
}


// 通过项目id获取人员信息
export function personnelInformation(msg) {
    return request({
        url: "v3/001/",
        method: "post",
        data: msg
    })
}

// 获取公司与项目的树结构关系图
export function relationship(msg) {
    return request({
        url: "v3/020/",
        method: "post",
        data: msg
    })
}